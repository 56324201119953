<template>
  <!-- login Modal -->
  <div
    id="logInModal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="logInModalTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header mb-4 border-0">
          <h4 class="modal-title fs-3">Log in</h4>
          <button
            id="closeLogInModal"
            type="button"
            class="btn-close float-end m-0"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body px-4">
          <!-- Content -->
          <!-- Card -->
          <div class="card border-0">
            <!-- Card Body -->
            <div class="bg-transparent card-body p-0">
              <!-- alert error message -->
              <div
                v-if="errors != null && !in_Submission"
                class="alert alert-danger d-flex align-items-center text-white"
                role="alert"
              >
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
                    viewBox="0 0 16 16"
                    role="img"
                    aria-label="Warning:"
                  >
                    <path
                      d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
                    />
                  </svg>
                  {{ errors }}
                </div>
              </div>
              <vee-form :validationSchema="loginSchema" @submit="logIn">
                <!-- Form for the email-->
                <div class="mb-4">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <label class="form-label text-muted" for="loginFormPassword"
                      >Your email</label
                    >
                  </div>

                  <div class="input-group input-group-merge">
                    <vee-field
                      type="email"
                      class="form-control p-3"
                      name="email"
                      id="logInemail"
                      placeholder="email@limu.edu.ly"
                      aria-label="email@limu.edu.ly"
                      required
                    />
                  </div>

                  <error-message name="email" class="text-danger" />
                </div>
                <!-- End Form -->

                <!-- Form for the password -->
                <div class="mb-4">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <label class="form-label text-muted" for="loginFormPassword"
                      >Password</label
                    >
                    <a class="btn btn-link" @click.prevent="goToResetPassword"
                      >Forgot Password?</a
                    >
                  </div>

                  <div class="input-group input-group-merge">
                    <vee-field
                      type="password"
                      class="form-control p-3"
                      name="password"
                      id="logInpassword"
                      placeholder="8+ characters required"
                      aria-label="8+ characters required"
                      required
                    />
                  </div>

                  <error-message name="password" class="text-danger" />
                  <!-- End Form -->

                  <!-- Checkbox -->
                  <div class="form-check mb-4">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="loginFormRememberMeCheck"
                      name="loginFormRememberMeCheck"
                    />
                    <label
                      class="form-check-label"
                      for="loginFormRememberMeCheck"
                    >
                      Remember me</label
                    >
                  </div>
                  <!-- End Checkbox -->

                  <!-- login buttons -->
                  <div class="d-grid mb-4" v-if="!in_submission">
                    <button type="submit" class="btn btn-primary btn-lg">
                      Log in
                    </button>
                    <span class="divider-center my-2">OR</span>
                    <a
                      class="btn btn-white btn-lg border border-1 not-allowed disabled"
                      href="#"
                    >
                      <span
                        class="d-flex justify-content-center align-items-center"
                      >
                        <img
                          class="avatar avatar-xss me-2"
                          src="/img/google-icon.svg"
                          alt="Image Description"
                        />
                        log in with Google
                      </span>
                    </a>
                  </div>
                  <!-- spinners -->
                  <div
                    class="d-flex justify-content-center my-2"
                    v-if="in_submission"
                  >
                    <div class="spinner-border text-primary" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </div>
              </vee-form>
            </div>
            <!-- End Card Body -->
          </div>
          <!-- End Card -->
          <!-- End Content -->
        </div>
      </div>
    </div>
  </div>
  <!-- End Modal -->
  <overlay></overlay>
  <popUpMessage />
</template>

<script>
import axiosConfig from "@/includes/axiosConfig.js";
import overlay from "@/components/staticPages/overlay.vue";

export default {
  name: "logInModal",
  components: {
    overlay,
  },
  data() {
    return {
      loginSchema: {
        email: "required|regex:^[A-Za-z0-9._%+-]+@limu.edu.ly$",
        password: "required",
      },
      in_submission: false,
      errors: null,
    };
  },
  methods: {
    async logIn(val) {
      this.errors = null;
      this.in_submission = true;
      document.getElementById("overlay").classList.toggle("d-none");
      await axiosConfig
        .post("/login", val)
        .then(async (res) => {
          console.log(res);
          await this.$store.dispatch("checkIfAuthenticated");
          this.$router.push({ name: "main" });
          document.getElementById("closeLogInModal").click();
        })
        .catch(async (err) => {
          console.log(err.response);
          if (err.response ? err.response.status == 422 : false) {
            this.errors = Object.values(err.response.data.errors)[0][0];
          } else if (err.response ? err.response.status == 404 : false) {
            await this.$store.dispatch("checkIfAuthenticated");
            this.$router.push({ name: "main" });
            document.getElementById("closeLogInModal").click();
          } else {
            this.errors =
              "An error has been occured, please try again later or check your network";
          }
          document.getElementById("overlay").classList.toggle("d-none");
          return;
        });
      this.in_submission = false;
      setTimeout(() => {
        this.errors = null;
      }, 10000);
    },
    goToResetPassword() {
      this.$router.push({ name: "resetPassword" });
      document.getElementById("closeLogInModal").click();
    },
  },
};
</script>

<style scoped>
.card {
  border-width: 0;
  box-shadow: unset !important;
}
</style>
